import { FormattedMessage } from "react-intl";

export const CONTENT_PAGE = [
  {
    id: 1,
    title: "app.Help.Index.Link_1",
    content: [
      {
        id: 1,
        multipleImg: ["images/how_video-1.png", "images/how_video-1-2.png"],
        styles: { marginTop: "2.5rem" },
        text: (
          <>
            <p>
              <FormattedMessage id="app.Help.Content_1.uploadVideosTitle" />
            </p>
            <p className="mt-1 font-bold">
              1.-{" "}
              <FormattedMessage id="app.Help.Content_1.uploadFromComputer" />
            </p>
            <ul className="ml-2">
              <li>
                {" "}
                <FormattedMessage id="app.Help.Content_1.selectVideoFile" />
              </li>
              <li>
                {" "}
                <FormattedMessage id="app.Help.Content_1.orDragFile" />
              </li>
            </ul>
            <p>
              <FormattedMessage id="app.Help.Content_1.analyzeVideoFromApp" />
            </p>
            <ul className="ml-2 link">
              <li>
                {" "}
                <FormattedMessage id="app.Help.Content_1.howToRecordIPhone" />
              </li>
              <li>
                {" "}
                <FormattedMessage id="app.Help.Content_1.howToRecordAndroid" />
              </li>
            </ul>
          </>
        ),
      },
      {
        id: 2,
        img: "images/how_video-2.png",
        text: (
          <>
            <p className="font-bold">
              2.- <FormattedMessage id="app.Help.Content_2.pasteYouTubeLink" />
            </p>
            <ul className="ml-2">
              <li>
                {" "}
                <FormattedMessage id="app.Help.Content_2.pasteYoutubeURL" />
              </li>
              <li>
                {" "}
                <FormattedMessage id="app.Help.Content_2.analyzeSpecificSection" />
              </li>
            </ul>
            <p className="link ml-2 mb-2">
              <FormattedMessage id="app.Help.Content_2.howToCopyYoutubeTimestamp" />
            </p>
          </>
        ),
      },
      {
        id: 3,
        img: "images/how_video-3.png",
        text: (
          <>
            <p className="font-bold">
              <FormattedMessage id="app.Help.Content_3.videoTypes" />
            </p>
            <ul className="ml-2">
              <li>
                {" "}
                <FormattedMessage id="app.Help.Content_3.faceType" />
              </li>
              <li>
                {" "}
                <FormattedMessage id="app.Help.Content_3.lengthType" />
              </li>
              <li>
                {" "}
                <FormattedMessage id="app.Help.Content_3.formatType" />
              </li>
            </ul>
          </>
        ),
      },
      {
        id: 4,
        img: "images/how_video-4.png",
        text: (
          <>
            <p>
              <FormattedMessage id="app.Help.Content_4.deepFakeScore" />
            </p>
            <ul className="ml-2">
              <li>
                {" "}
                <FormattedMessage id="app.Help.Content_4.noDeepFake" />
              </li>
              <li>
                {" "}
                <FormattedMessage id="app.Help.Content_4.potentialDeepFake" />
              </li>
              <li>
                {" "}
                <FormattedMessage id="app.Help.Content_4.likelyDeepFake" />
              </li>
            </ul>
            <p className="mt-1">
              <FormattedMessage id="app.Help.Content_4.feedback" />
            </p>
            <p className="mt-1">
              <FormattedMessage id="app.Help.Content_4.downloadReport" />
            </p>
          </>
        ),
      },
    ],
  },
  {
    id: 2,
    title: "app.Help.Index.Link_2",
    mainTitle: "app.Help.Link_2.Content_1.title",
    content: [
      {
        id: 1,
        multipleImg: ["images/how_video-1.png", "images/how_video-1-2.png"],
        styles: { marginTop: "2.5rem" },

        text: (
          <>
            <p>
              <FormattedMessage id="app.Help.Link_2.Content_1.submitImages" />
            </p>
            <p className="mt-1 font-bold">
              1.-{" "}
              <FormattedMessage id="app.Help.Link_2.Content_1.uploadImage" />
            </p>
            <ul className="ml-2">
              <li>
                {" "}
                <FormattedMessage id="app.Help.Link_2.Content_1.selectImageFile" />
              </li>
              <li>
                {" "}
                <FormattedMessage id="app.Help.Link_2.Content_1.dragImageFile" />
              </li>
            </ul>
          </>
        ),
      },
      {
        id: 2,
        img: "images/how_image-2.png",
        text: (
          <>
            <p className="font-bold">
              2.-{" "}
              <FormattedMessage id="app.Help.Link_2.Content_2.copyPasteImage" />
            </p>
            <p className="ml-2 mt-1">
              <FormattedMessage id="app.Help.Link_2.Content_2.copyPasteImageDesc" />
            </p>
          </>
        ),
      },
      {
        id: 3,
        img: "images/how_image-3.png",
        text: (
          <>
            <p className="font-bold">
              3.{" "}
              <FormattedMessage id="app.Help.Link_2.Content_3.copyPasteImageURL" />
            </p>
            <p className="mt-1">
              <FormattedMessage id="app.Help.Link_2.Content_3.imageTypes" />
            </p>
            <ul className="ml-2">
              <li>
                {" "}
                <FormattedMessage id="app.Help.Link_2.Content_3.imageSize" />
              </li>
              <li>
                {" "}
                <FormattedMessage id="app.Help.Link_2.Content_3.imageFormat" />
              </li>
            </ul>
          </>
        ),
      },
      {
        id: 4,
        img: "images/how_image-4.png",
        text: (
          <>
            <p>
              <FormattedMessage id="app.Help.Link_2.Content_4.aiGeneratedScore" />
            </p>
            <ul className="ml-2">
              <li>
                {" "}
                <FormattedMessage id="app.Help.Link_2.Content_4.noAI" />
              </li>
              <li>
                {" "}
                <FormattedMessage id="app.Help.Link_2.Content_4.potentialAI" />
              </li>
              <li>
                {" "}
                <FormattedMessage id="app.Help.Link_2.Content_4.likelyAI" />
              </li>
            </ul>
            <p className="mt-1">
              <FormattedMessage id="app.Help.Link_2.Content_4.improvingSystem" />
            </p>
          </>
        ),
      },
    ],
  },

  {
    id: 7,
    title: "app.Help.Link_7.Content_1.title",
    content: [
      {
        styles: { width: "100px", height: "170px", margin: "auto" },
        id: 1,
        img: "images/help/use_smartphone.png",
        text: (
          <>
            <p>
              <FormattedMessage id="app.Help.Link_7.Content_1.text1" />
            </p>
            <p className="mt-1">
              <FormattedMessage id="app.Help.Link_7.Content_1.text2" />
            </p>
          </>
        ),
      },
    ],
  },
];
