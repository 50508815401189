import moment from "moment-timezone";
import "moment/locale/es";
import { formatDateApi, formatDatePicker } from "./Constant";
export default {
  trimCharacer: (str: string, character?: string): string => {
    str = str.replace(/\s/g, character ? character : "");
    let res: string = str;
    return res;
  },
  fixeToTwiDecimals: (value: number): string => {
    return (+value).toFixed(2);
  },
  formatToMoney: (value: number): string => {
    return `S/ ${(+value).toFixed(2)}`;
  },
};

export function formatToMoney(value) {
  if (value === undefined) {
    return `S/0.00`;
  }

  if (isNaN(value)) {
    return `S/${+value}`;
  }
  return `S/ ${(+value).toFixed(2)}`;
}

export function maxLengthCheck(evt) {
  const theEvent = evt || window.event;
  let key;
  // Handle paste
  if (theEvent.type === "paste") {
    key = evt.clipboardData.getData("text/plain");
  } else {
    // Handle key press
    key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
  }
  const regex = /[0-9]|\./;
  if (!regex.test(key)) {
    theEvent.returnValue = false;
    if (theEvent.preventDefault) theEvent.preventDefault();
  }
}

export function removeDays(date, days) {
  date = new Date(date);
  date.setDate(date.getDate() - days);
  return date;
}

export function formatDate(date) {
  return moment(date).format(formatDateApi);
}

export function formatDatePrintRemmisse(dateFrom, dateTo) {
  const dateWeek = moment(dateFrom).format("dddd");
  const dateFormattedStart = moment(dateFrom).format(formatDatePicker);
  const dateFormattedEnd = moment(dateTo).format("DD MMM, YYYY");
  return dateWeek + " " + dateFormattedStart + " - " + dateFormattedEnd;
}

export const pathServer = {
  PATH_ICONS: `/icons/`,
  PATH_LOGO: `/logo/`,
  PATH_IMG: `/images/`,
  PATH_FAVICON: `/favicon/`,
};
