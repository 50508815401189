export const CAROUSEL_ITEMS = [
  {
    id: 1,
    title: "app.coming.Header_1",
    description: "app.coming.Text_1",
    img: "images/coming__soon/beauty.gif",
  },
  {
    id: 2,
    title: "app.coming.Header_2",
    description: "app.coming.Text_2",
    img: "images/coming__soon/image_2.png",
  },
];
