import React, { useContext } from "react";
import { LanguageContext } from "../../context/i18n/LanguageProvider";
import { DownOutlined } from "@ant-design/icons";
import "./style.scss";
import { Button, Dropdown } from "antd";

interface LanguageSelectorProps {
  className?: string;
}

const TLanguageSelector = (props: LanguageSelectorProps) => {
  const { locale, setLocale } = useContext(LanguageContext);

  const items = [
    {
      key: "1",
      label: "EN",
      onClick: () => __onHandle("en"),
    },
    {
      key: "2",
      label: "ES",
      onClick: () => __onHandle("es"),
    },
  ];

  const __onHandle = (language: string) => {
    setLocale(language);
  };

  return (
    <div className={`LanguageSelector ${props.className}`}>
      <Dropdown menu={{ items }}>
        <Button>
          {locale.toUpperCase()} <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};

TLanguageSelector.displayName = "TLanguageSelector";

TLanguageSelector.defaultProps = {
  className: "",
};

export default TLanguageSelector;
