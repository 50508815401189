import React from "react";

import { Typography, Button } from "antd";

import "./style.scss";
import { SERVICES } from "./Constants";
import { pathServer } from "../../utilities/Function";
import { t } from "../../utilities/Message";
import { useHistory } from "react-router-dom";
import { ROUTES_GENERAL } from "../../utilities/Constant";

const { Text } = Typography;

interface ServiceProps {
  className?: string;
  // Define your component's state here
}

const TService = (props: ServiceProps) => {
  const history = useHistory();

  const onClick = () => {
    history.push(ROUTES_GENERAL.HELP);
    window.scrollTo(0, 0);
  };

  return (
    <div className="services max-padding">
      <div className="service-container">
        {SERVICES.map((service) => (
          <div key={service.id} className="services__card">
            <div className="services__card-body">
              {/* <Text className="services__title" type="secondary">
                {t("app.Landing.WithVerifai")}
              </Text> */}
              <Text
                className="services__subTitle font-bold"
                style={{ fontSize: "1.3rem" }}
              >
                {t(service.title, "")}
              </Text>
              <Text className="mt-1 mb-2 services__description font-regular">
                {t(service.description, "")}
              </Text>

              {/* {service.id === 2 ? (
                <Button className="mt-1 mb-2 button-outlined" disabled>
                  Coming soon
                </Button>
              ) : (
                <Button className="mt-1 mb-2 button-outlined" onClick={onClick}>
                  {t("app.Landing.LearnMore")}
                </Button>
              )} */}
            </div>
            <img
              src={pathServer.PATH_IMG + service.img}
              alt="service"
              width={300}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

TService.displayName = "TService";

TService.defaultProps = {
  className: "",
};

export default TService;
