import React, { useRef } from "react";
import { Carousel, Button } from "antd";

import { pathServer } from "../../utilities/Function";

import "./style.scss";
import { CAROUSEL_ITEMS } from "./Constants";
import { ReactSVG } from "react-svg";
import { t } from "../../utilities/Message";
import { useHistory } from "react-router-dom";
import { ROUTES_GENERAL } from "../../utilities/Constant";

// Define your props here
interface CarouselProps {
  className?: string;
}

function CustomNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="carousel__dots-next pointer" onClick={onClick}>
      <ReactSVG src={pathServer.PATH_ICONS + "ic_right.svg"} />
    </div>
  );
}

function CustomPrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="carousel__dots-prev pointer" onClick={onClick}>
      <ReactSVG src={pathServer.PATH_ICONS + "ic_left.svg"} />
    </div>
  );
}

const TCarousel = (props: CarouselProps) => {
  const carouselRef = useRef(null);
  const history = useHistory();
  const onClick = () => {
    history.push(ROUTES_GENERAL.HOW_WORKS);
    window.scrollTo(0, 0);
  };
  const onChange = (currentSlide: number) => {};

  return (
    <div className={`carousel ${props.className}`}>
      <Carousel
        className="max-width"
        ref={carouselRef}
        swipe
        afterChange={onChange}
        dots={{ className: "custom-dots" }}
      >
        {CAROUSEL_ITEMS.map((carousel) => (
          <div className="carousel__container" key={carousel.id}>
            <div className="carousel__dots">
              <CustomPrevArrow onClick={() => carouselRef.current.prev()} />
              <CustomNextArrow onClick={() => carouselRef.current.next()} />
            </div>
            <div className="carousel__content m-auto">
              <h1 className="services__title font-bold">
                {t("app.Landing.WithVerifai")}
              </h1>
              <h2 className="services__subTitle font-bold">
                {t(carousel.title, "")}
              </h2>
              <p className="mt-1 services__description">
                {t(carousel.description, "")}
              </p>
              <Button className="mt-1 mb-2 button-outlined" onClick={onClick}>
                {t("app.Landing.LearnMore")}
              </Button>
            </div>
            <div className="m-auto">
              <img src={carousel.img} alt="service" width={300} />
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

TCarousel.displayName = "TCarousel";

TCarousel.defaultProps = {
  className: "",
};

export default TCarousel;
