import { Result, Button } from "antd";
import React from "react";
import { withLanding } from "../../hocs/withLanding";
import { Link } from "react-router-dom";
import "./styles.scss";

const ErrorPage = () => {
  return (
    <Result
      className="error_page"
      status="404"
      title="404"
      subTitle="La página que buscas no existe."
      extra={
        <Button type="primary">
          {" "}
          <Link to="/">Go Home</Link>{" "}
        </Button>
      }
    />
  );
};

export default withLanding(ErrorPage);
