import React, { useContext, useEffect } from "react";
import { withLanding } from "../../hocs/withLanding";

import "./styles.scss";
import { Link, useLocation } from "react-router-dom";
import { LanguageContext } from "../../context/i18n/LanguageProvider";
import { page_view } from "../../hooks/usePageView";
import { EVENTS_TGM } from "../../utilities/Events";

const ConditionsPageV2 = () => {
  const location = useLocation();
  const { locale } = useContext(LanguageContext);
  const isEnglish = location.pathname.includes("special-conditions")
    ? "en"
    : "es";

  const LINK_TELEFONICA = (
    <Link
      style={{ color: "#4343ff" }}
      to={{ pathname: "https://www.tu.com/" }}
      target="_blank"
    >
      {" "}
      www.tu.com
    </Link>
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    page_view({ page_type: EVENTS_TGM.conditions, locale });
  }, []);

  return (
    <div className="conditions-page how-it-works mb-2">
      <h1 className="text-center font-semibold mt-2 mb-2">
        {isEnglish === "es"
          ? "Condiciones Particulares de la Beta del Servicio VerifAI"
          : "Specific Conditions of VerifAI Service"}
      </h1>
      {isEnglish === "es" ? (
        <div className="max-width  mb-3 conditions-page-body">
          <p>
            Estas Condiciones Particulares son complementares a los Términos y
            Condiciones Generales de Uso de los Servicios Tu.com disponibles en{" "}
            {LINK_TELEFONICA} y son especificas para la fase de prueba ("beta")
            del Servicio VerifAI. (en adelante, el "Servicio"), provisto por
            <b> TELEFÓNICA INNOVACIÓN DIGITAL, S.L.U.</b>, empresa con NIF nº
            B83188953, domicilio social en Ronda de la Comunicación, s/n,
            Edificio Central, 28050, Madrid, e inscrita en el Registro Mercantil
            de Madrid al tomo 42.773, folio 213, inscripción 79, hoja M-296237
            (en adelante,
            <b>"Telefónica"</b>).
          </p>
          <p className="mt-1 mb-1 font-bold">1. ACEPTACIÓN</p>

          <p>
            La aceptación, sin reservas, de estas Condiciones Particulares es
            indispensable para el uso del Servicio por cualquier persona
            interesada.
            <b>
              {" "}
              En el caso de que el Usuario no esté conforme con estas
              Condiciones Particulares no debe utilizar el servicio.
            </b>
          </p>

          <p className="mt-1 mb-1 font-bold">
            2. DESCRIPCI&Oacute;N DEL SERVICIO
          </p>
          <p>
            VerifAI es una solución desarrollada por Telefónica que tiene como
            objetivo detectar si un determinado contenido proporcionado por el
            Usuario fue generado o modificado de alguna forma por sistemas de
            Inteligencia Artificial (“IA”).
            <br />
            VerifAI se presta en modalidad de prueba, sin ninguna garantía de
            eficacia y/o disponibilidad, y no está libre de errores y defectos.{" "}
            <br />
            De este modo, VerifAI permite al Usuario conocer la probabilidad (en
            un porcentaje estimado) de que el contenido consultado se haya
            generado o modificado mediante IA. El resultado se ofrece a título
            estimativo. Telefónica NO ofrece ninguna garantía y no proporciona
            ninguna conformidad sobre la exactitud o fiabilidad del resultado.{" "}
            <br /> Para utilizar el Servicio, el Usuario podrá proporcionar el
            contenido en formato de imagen, video (en adelante, el{" "}
            <b>“Contenido”</b>), sin perjuicio de que Telefónica pueda modificar
            o habilitar nuevos formatos de consulta de forma discrecional.
            <br /> VerifAI está desarrollado sobre licencias open source de
            terceros y bases de datos públicas, entrenadas por TELEFÓNICA entre
            las que se encuentran:
          </p>

          <ul>
            {" "}
            <li>Reddit_eli5 de ELI5 con licencia BSD-License</li>
            <li>Open_qa de WikiQA con licencia PWC Custom</li>
            <li>Wiki_csai de Wikipedia con licencia CC-BY-SA</li>
            <li>Medicine de Medical Dialog</li>
            <li>Finance de FiQA</li>
            <li>AI-image-detector de umm-maybe con licencia CC-BY-4.0</li>
          </ul>
          <p className="mt-1 mb-1 font-bold">
            3. NUESTRO COMPROMISO CON VERIFAI
          </p>
          <p>
            A trav&eacute;s de VerifAI TELEF&Oacute;NICA tiene como
            intenci&oacute;n colaborar con la sociedad proporcionando
            herramientas que permitan a las personas interesadas conocer una
            estimaci&oacute;n sobre si determinada informaci&oacute;n o
            contenido es originalmente desarrollado por una persona o si es
            resultado del uso de Inteligencia Artificial, y as&iacute; ayudar a
            formar una opini&oacute;n m&aacute;s realista acerca de dicho
            contenido e informaci&oacute;n relacionada con &eacute;ste.
          </p>
          <p>
            En el marco de este prop&oacute;sito, TELEF&Oacute;NICA asume frente
            a los Usuarios de VerifAI ciertos compromisos relevantes:
          </p>
          <div className="ml-1">
            <p>
              i. Minimizamos al m&aacute;ximo el tratamiento de datos personales
              de los USUARIOS, no es necesario que el usuario se registre para
              acceder al Servicio.
            </p>
            <p>
              ii. No almacenamos el Contenido y no lo compartiremos con
              terceros. Solo almacenamos una firma digital o hash del Contenido
              asociado a la estimaci&oacute;n que se ha generado, ello con el
              fin de facilitar el acceso a la estimaci&oacute;n calculada para
              dicho Contenido por aquellos otros USUARIOS que usen VerifAI para
              un Contenido ya consultado antes. Un hash es una cadena de texto
              codificada formada por n&uacute;mero y letras, irrepetible, que
              representan inequ&iacute;vocamente el Contenido. El hash no es
              reversible, es decir, el Contenido no se puede obtener a
              trav&eacute;s del hash y, por eso, no almacenamos el Contenido
              consultado.
            </p>
            <p>
              iii. Recordamos que el resultado no es conclusivo, exacto ni
              fiable, es un punto de partida a trav&eacute;s del cual el Usuario
              puede hacer, por su cuenta y riesgo, un an&aacute;lisis m&aacute;s
              profundo acerca del origen del Contenido.
            </p>
            <p>
              iv. TELEF&Oacute;NICA proporcionar&aacute; el Servicio conforme a
              los t&eacute;rminos dispuestos en los{" "}
              <b>Términos de Uso de los Servicios Tu.com</b>, disponibles en{" "}
              {LINK_TELEFONICA} .
            </p>
            <p>
              Mantendremos un canal de contacto a través del cual el USUARIO
              podrá contactarnos para consultas relacionadas con el Servicio y/o
              acerca del resultado de la Consulta, en caso de que no esté de
              acuerdo con el mismo y/o tenga dudas al respecto. Telefónica se
              reserva el derecho (pero no la obligación) a habilitar mecanismos
              para que bien Telefónica, terceros, y/o el propio Usuario puedan
              (1) solicitar la revisión del resultado para un Contenido
              concreto, así como (2) listar firmas digitales o hashes de
              Contenidos sobre los que el Servicio no funcionará y sobre los que
              no se devolverá resultado o estimación alguna. Estos canales de
              contacto, y los mecanismos indicados, podrán estar sujetos a
              Condiciones Generales que deberán de ser leídas y aceptadas antes
              de su utilización.
            </p>
          </div>

          <p className="mt-1 mb-1 font-bold">
            4. TUS COMPROMISOS Y OBLIGACIONES COMO USUARIO DE VERIFAI
          </p>
          <p>El USUARIO se compromete a:</p>
          <div className="ml-1">
            <p>
              a) Hacer un uso del Servicio de una forma correcta, diligente y
              acorde con la ley, con los{" "}
              <b>
                {" "}
                Términos de Uso de los servicios Tu.com disponibles en{" "}
                {LINK_TELEFONICA}{" "}
              </b>{" "}
              y con las presentes Condiciones Particulares de VerifAI y, en
              particular, a abstenerse de utilizarlo con fines distintos de los
              expresamente permitidos en estas Condiciones Generales, en otras
              condiciones aplicables o del aviso legal de la web de VerifAI.
            </p>
            <p>
              b) Hacerse &uacute;nico responsable del Contenido que consulta a
              trav&eacute;s de VerifAI y de la divulgaci&oacute;n que haga del
              resultado consultado, de conformidad con lo establecido en estas
              Condiciones Generales
            </p>
            <p>
              c) Poner en conocimiento de TELEF&Oacute;NICA aquellas incidencias
              que le impidan o dificulten poder acceder al Servicio. As&iacute;
              como, cualquier incidencia, que el Usuario quiera comunicar a
              TELEF&Oacute;NICA debido al resultado obtenido y/o acerca de un
              Contenido que resulte ser de titularidad del Usuario y que este
              pretenda limitar su consulta amparado en un derecho
              leg&iacute;timo.
            </p>
            <p>
              d) Cumplir con todo lo establecido en estas Condiciones
              Particulares y en la legislaci&oacute;n aplicable.
            </p>
          </div>
          <p className="mt-1 mb-1 font-bold">5. CONTACTO Y RECLAMACIONES</p>
          <p>
            El USUARIO podr&aacute; comunicarse con TELEF&Oacute;NICA a
            trav&eacute;s de la siguiente direcci&oacute;n de correo
            electr&oacute;nico:
          </p>
          <p className="mt-0">
            El USUARIO podr&aacute; enviar cualquier solicitud o
            reclamaci&oacute;n relacionada con el Servicio y/o con el resultado
            de la Consulta, como, por ejemplo, en el caso de que entienda que la
            estimativa proporcionada por VerifAI como resultado de la Consulta
            no es adecuada y/o en el caso de que quiera comunicar cualquier
            derecho propio y/ de tercero acerca del Contenido consultado,
            as&iacute; como solicitar limitaciones al uso de este Contenido
            (propio) por parte de VerifAI.
          </p>
          <p>
            A estos efectos, el soporte y atenci&oacute;n al cliente de los
            USUARIOS se realizar&aacute; online a trav&eacute;s de dicha
            direcci&oacute;n y ser&aacute; atendido a la mayor brevedad, en
            horario laboral de lunes a viernes, siempre que no sea festivo.
          </p>
        </div>
      ) : (
        <div className="max-width  mb-3 conditions-page-body">
          <p>
            These particular conditions are complementary to the General Terms
            and Conditions of Use of the Tu.com Services available at
            {LINK_TELEFONICA}
            and are specific to the testing phase("beta") of VerifAI Service
            (hereinafter, the "Service"), provided by
            <b> TELEFÓNICA INNOVACIÓN DIGITAL, S.L.U.</b>, company with Tax
            Identification Number B83188953, registered office at Ronda de la
            Comunicación, s/n, Edificio Central, 28050, Madrid, and registered
            in the Commercial Registry of Madrid in volume 42.773, folio 213,
            entry 79, page M-296237 (hereinafter, <b>"Telefónica"</b>).
          </p>

          <p className="mt-1 mb-1 font-bold">1. ACCEPTANCE</p>

          <p>
            The acceptance, without reservation, of these Special Conditions is
            indispensable for the use of the Service by any interested party. In
            the event
            <b>
              {" "}
              that the User does not agree with these Special Conditions, he/she
              must not use the Service.
            </b>
          </p>

          <p className="mt-1 mb-1 font-bold">2. Description of the Service</p>
          <p>
            VerifAI is a solution developed by Telefónica that aims to detect
            whether a certain content provided by the User was generated or
            modified in some way by Artificial Intelligence (“AI”) systems.{" "}
            <br />
            VerifAI is provided in trial mode, without any guarantee of efficacy
            and/or availability, it is not free of errors and defects.
            <br />
            In this way, VerifAI allows the User to know the probability (in an
            estimated percentage) that the content consulted has been generated
            or modified by AI. The result is provided as an estimate. Telefónica
            makes NO warranty and does not provide any representation as to the
            accuracy or reliability of the result.
            <br />
            In order to use the Service, the User may provide content in image,
            video or text format (hereinafter, the <b>“Content”</b>), without
            prejudice to Telefónica's right to modify or enable new consultation
            formats at its discretion.
            <br />
            VerifAI is developed on third party open source licenses and public
            databases, trained by TELEFÓNICA, among which we find:
          </p>

          <ul>
            <li>Reddit_eli5-ELI5 under BSD-License</li>
            <li>Open_qa-WikiQA under PWC Custom license</li>
            <li>Wiki_csai-Wikipedia under CC-BY-SA license</li>
            <li>Medicine of Medical Dialogue</li>
            <li>Finance of FiQA</li>
            <li>AI-image-detector-umm-maybe under CC-BY-4.0 license</li>
          </ul>
          <p className="mt-1 mb-1 font-bold">3. OUR COMMITMENT TO VERIFAI</p>
          <p>
            Through VerifAI, Telefónica intends to collaborate with society by
            providing tools that allow interested parties to estimate whether
            certain information or content is originally developed by a person
            or whether it is the result of the use of Artificial Intelligence,
            and thus help to form a more realistic opinion about such content
            and related information.
          </p>
          <p>
            Within the framework of this purpose, Telefónica undertakes certain
            relevant commitments to VerifAI Users:
          </p>
          <div className="ml-1">
            <p>
              i. We minimize the processing of Users' personal data as much as
              possible, it is not necessary for the User to register in order to
              access the Service.
            </p>
            <p>
              ii. We do not store the Content and will not share it with third
              parties. We only store a digital signature or hash of the Content
              associated with the estimate that has been generated, in order to
              facilitate access to the estimate calculated for that Content by
              other Users who use VerifAI for Content that has already been
              viewed. A hash is an encoded text string consisting of
              unrepeatable numbers and letters that unambiguously represent the
              Content. The hash is not reversible, i.e. the Content cannot be
              obtained through the hash and, therefore, we do not store the
              Content consulted.
            </p>
            <p>
              iii. We remind you that the result is not conclusive, exact or
              reliable, it is a starting point through which the User can make,
              at his/her own risk, a deeper analysis about the origin of the
              Content.
            </p>
            <p>
              iv. Telefónica will provide the Service in accordance with the
              terms set out in the <b>Tu.com Services Terms of Use</b>,
              available at {LINK_TELEFONICA}.
            </p>
            <p>
              v. We will maintain a contact channel through which the USER may
              contact us for queries related to the Service and/or about the
              outcome of the query, in case the USER does not agree with the
              outcome and/or has doubts about it. Telefónica reserves the right
              (but not the obligation) to provide mechanisms so that Telefónica,
              third parties and/or the USER can (1) request a review of the
              result for a specific Content, as well as (2) list digital
              signatures or hashes of Content for which the Service will not
              work and for which no result or estimate will be returned. These
              contact channels, and the mechanisms indicated, may be subject to
              special conditions that must be read and accepted prior to use.
            </p>
          </div>

          <p className="mt-1 mb-1 font-bold">
            4. YOUR COMMITMENTS AND OBLIGATIONS AS A USER OF VERIFAI
          </p>
          <p>The USER undertakes to:</p>
          <div className="ml-1">
            <p>
              a) To use the Service correctly, diligently and in accordance with
              the law, with the{" "}
              <b>
                Terms of Use of the Tu.com services available at{" "}
                {LINK_TELEFONICA}
              </b>{" "}
              and with these Special Conditions of VerifAI and, in particular,
              to refrain from using it for purposes other than those expressly
              permitted in these General Conditions, in other applicable
              conditions or in the legal notice of the VerifAI website.
            </p>
            <p>
              b) To be solely responsible for the Content that you consult
              through VerifAI and for the disclosure of the result consulted, in
              accordance with the provisions of these General Terms and
              Conditions.
            </p>
            <p>
              c) To inform TELEFÓNICA of any incidents that prevent or hinder
              access to the Service. As well as any incident that the User
              wishes to report to TELEFÓNICA due to the result obtained and/or
              regarding Content that is owned by the User and that the User
              intends to limit its consultation based on a legitimate right.
            </p>
            <p>
              d) To comply with all the provisions of these Particulars, the
              Terms of Use of the Tu.com Services and applicable law.
            </p>
          </div>
          <p className="mt-1 mb-1 font-bold">5. CONTACT AND COMPLAINTS</p>
          <p>
            The User may contact Telefónica at the following e-mail address:
          </p>
          <p className="mt-0">
            The User may submit any request or complaint regarding the Service
            and/or the result of the Consultation, such as, for example, in the
            event that he/she believes that the estimate provided by VerifAI as
            a result of the Consultation is not adequate and/or in the event
            that he/she wants to communicate any own and/or third party rights
            regarding the Consulted Content, as well as request limitations on
            the use of this (own) Content by VerifAI.
          </p>
          <p>
            For these purposes, support and customer service for Users will be
            provided online through the aforementioned address and will be
            attended to as soon as possible, during working hours from Monday to
            Friday, provided that it is not a public holiday.
          </p>
        </div>
      )}
    </div>
  );
};

export default withLanding(ConditionsPageV2);
