import React, { useEffect, useState, Profiler } from "react";
import THeader from "../components/Header";
import { Button } from "antd";
import { ArrowUpOutlined } from "@ant-design/icons";

import "./styles.scss";
import TFooter from "../components/Footer";

export default function WLandingHome({ children }) {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 200) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const onRender = (
    id,
    phase,
    actualDuration,
    baseDuration,
    startTime,
    commitTime
  ) => {
    // console.log(`${id} (${phase}): ${actualDuration}ms`);
    // Puedes realizar acciones adicionales aquí, como enviar datos a un servidor o realizar análisis más avanzados.
  };
  return (
    <>
      <THeader />
      <Profiler id="MyComponent" onRender={onRender}>
        <main>{children}</main>
      </Profiler>
      {/* <TermsAndConditions /> */}
      <TFooter />
      <div
        style={{ marginTop: "-80px", marginLeft: "20px" }}
        className="scroll"
      >
        {showButton && (
          <Button
            shape="circle"
            className=""
            type="primary"
            size="large"
            onClick={scrollToTop}
            icon={<ArrowUpOutlined />}
          ></Button>
        )}
      </div>
    </>
  );
}

WLandingHome.propTypes = {};

export const withLanding = (Component) =>
  function (props) {
    return (
      <WLandingHome>
        <Component {...props} />
      </WLandingHome>
    );
  };
