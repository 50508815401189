import React, { useContext, useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Progress, Space, Input, Alert } from "antd";

import { useDropzone } from "react-dropzone";

import { ReactSVG } from "react-svg";
import { pathServer } from "../../utilities/Function";
import {
  INITIAL_ERROR,
  ITEMS,
  MESSAGES_DROPZONE,
  RequirementsFile,
  STATUS_FILE,
} from "./Constants";

import { t } from "../../utilities/Message";
import { FilesAvailable } from "../../../domain/entities/Api";
import "./style.scss";
import { file_uploaded } from "../../hooks/usePageView";
import { EVENTS_TGM } from "../../utilities/Events";
import { LanguageContext } from "../../context/i18n/LanguageProvider";
import { ROUTES_GENERAL, ROUTES_GENERAL_ESP } from "../../utilities/Constant";

interface DropzoneProps {
  className?: string;
  loading?: boolean;
  uploadFile?: (params: File) => void;
}

function TDropZoneItems() {
  return ITEMS.map((item) => (
    <li key={item.id}>
      <ReactSVG src={pathServer.PATH_ICONS + item.icon} className="item-icon" />
      <h2 className="from-lg uppercase">{t(item.title, "")}</h2>
      <div>
        <h2 className="to-md uppercase">{t(item.title, "")}</h2>
        <div>
          {item.items.map((it, index) => (
            <p className="mb-0" key={index}>
              <img
                src={pathServer.PATH_ICONS + "ic_list.svg"}
                className="mr-0"
                alt="icon"
                width={28}
              />{" "}
              {t(it, "")}
            </p>
          ))}
        </div>
      </div>
    </li>
  ));
}

const ProgressContent = ({ progress }) => (
  <div className="drag-overlay">
    <div className="drag-overlay_icon text-center">
      <UploadOutlined style={{ color: "#041E55", fontSize: "2rem" }} />
      <p>Upload content</p>
    </div>
    <Progress
      percent={progress}
      status={progress === 100 ? "success" : "active"}
    />
  </div>
);

//In the first call we can limit the progress to 50% , the second part is in the result page
const TIME_LIMIT_FIRST_SERVICE = 100;

const TIME_MILISECONDS = 80;

const TDropZone = (props: DropzoneProps) => {
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState(STATUS_FILE.initial);
  const [error, setError] = useState(INITIAL_ERROR);
  const [file, setFile] = useState(null);
  const { locale } = useContext(LanguageContext);

  const MESSAGES = {
    LENGTH: t(MESSAGES_DROPZONE.TEXT_LENGTH),
    FILE: t(MESSAGES_DROPZONE.FILE),
    DROP_TITLE: t("app.validation.length"),
    DROP_DESCRIPTION: t("app.validation.length.description"),
  };

  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      setStatus(STATUS_FILE.rejected);
      setError({
        title: MESSAGES.DROP_TITLE,
        description: MESSAGES.DROP_DESCRIPTION,
      });
    } else {
      //Call inmediatly the service upload-data
      onProcess(acceptedFiles[0]);
      setFile(acceptedFiles[0]);
      setStatus(STATUS_FILE.loading);
    }
  };

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    disabled: !!file,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    ...RequirementsFile,
  });

  const onProcess = (file?: File) => {
    if (file) {
      //Generate a source blob using the image
      const url = URL.createObjectURL(file);
      const typeFile = file.type.includes("video")
        ? FilesAvailable.video
        : FilesAvailable.image;
      localStorage.setItem("processedFile", url);
      localStorage.setItem("typeFile", typeFile);
      const data = { deviceSelected: "image" };
      file_uploaded({
        page_type: EVENTS_TGM.home,
        data,
        //@ts-ignore
        name: file?.path,
        locale,
      });
      props.uploadFile(file);
    } else {
      setStatus(STATUS_FILE.rejected);
      setError({
        title: "File Error",
        description: MESSAGES.FILE,
      });
    }
  };

  useEffect(() => {
    if (status === STATUS_FILE.loading) {
      let incrementProgress = 0;

      const intervalId = setInterval(() => {
        incrementProgress++;
        incrementProgress <= TIME_LIMIT_FIRST_SERVICE &&
          setProgress(incrementProgress);
        if (incrementProgress >= TIME_LIMIT_FIRST_SERVICE && !props.loading) {
          clearInterval(intervalId);
          setStatus(STATUS_FILE.finished);
          setProgress(0);
          setFile(null);
        }
      }, TIME_MILISECONDS);

      return () => clearInterval(intervalId);
    }
  }, [status, props.loading]);

  return (
    <div className={`dropzone ${props.className}`}>
      <div className="custom-upload" {...getRootProps()}>
        {isDragActive && (
          <div className="drag-overlay">
            <UploadOutlined style={{ color: "#041E55", fontSize: "2rem" }} />
            <p>Drop your file here</p>
          </div>
        )}
        {progress > 0 && <ProgressContent progress={progress} />}

        <div className="dropzone__body">
          <Space.Compact style={{ width: "100%" }} className="input-container">
            <Input
              disabled
              className={error.title ? "dropzone__body-error" : ""}
              placeholder={t("app.Landing.Banner.Search", "")}
            />
            <Button
              loading={props.loading}
              onClick={open}
              style={{ background: "#4343FF" }}
              icon={<UploadOutlined className="mr-0" />}
              type="primary"
            >
              <input {...getInputProps()} />
              {t("app.Landing.Banner.Upload", "")}
            </Button>
          </Space.Compact>

          {status === STATUS_FILE.rejected && (
            <Alert
              description={error.description}
              message={error.title}
              type="error"
              showIcon
              closable
              onClose={() => {
                setStatus(STATUS_FILE.initial);
                setError(INITIAL_ERROR);
              }}
            />
          )}
          <Space.Compact className={"terms_condition mt-2"}>
            <div className="dropzone__terms">
              {locale === "en" ? (
                <div>
                  By uploading a file you accept the
                  <a
                    href={ROUTES_GENERAL.CONDITIONS_EN}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    General Conditions
                  </a>{" "}
                  ,
                  <a
                    href={ROUTES_GENERAL.CONDITIONS_V2_EN}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    Particular Conditions
                  </a>{" "}
                  and
                  <a
                    href={ROUTES_GENERAL.PRIVACY}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    Privacy Policy{" "}
                  </a>
                  and that the result offered by VerifAI may not be exact or
                  reliable{" "}
                </div>
              ) : (
                <div>
                  Al subir un archivo aceptas las
                  <a
                    href={ROUTES_GENERAL.CONDITIONS}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    Condiciones Generales
                  </a>{" "}
                  ,
                  <a
                    href={ROUTES_GENERAL.CONDITIONS_V2}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    Condiciones Particulares
                  </a>{" "}
                  <a
                    href={ROUTES_GENERAL_ESP.PRIVACY}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    y Política de Privacidad{" "}
                  </a>
                  y que el resultado ofrecido por VerifAI puede no ser exacto ni
                  fiable{" "}
                </div>
              )}
            </div>
          </Space.Compact>

          <div className={"dropzone__info "}>
            <ul className="mt-2">{TDropZoneItems()}</ul>
          </div>
        </div>
      </div>
    </div>
  );
};

TDropZone.displayName = "TDropZone";

TDropZone.defaultProps = {
  className: "",
};

export default TDropZone;
