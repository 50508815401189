export const CAROUSEL_ITEMS = [
  {
    id: 1,
    title: "app.Landing.Carousel_1.Title",
    description: "app.Landing.Carousel_1.Description",
    img: "images/carousel/how_to_image.png",
  },
  {
    id: 2,
    title: "app.Landing.Carousel_2.Title",
    description: "app.Landing.Carousel_2.Description",
    img: "images/blink.gif",
  },
];
