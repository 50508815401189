export const SERVICES = [
  {
    id: 1,
    title: "app.Landing.Service_1.Header",
    description: "app.Landing.Service_1.Description",
    img: "services/gallery_1.png",
  },

  {
    id: 3,
    title: "app.Landing.Service_3.Header",
    description: "app.Landing.Service_3.Description",
    img: "services/gallery_3.png",
  },
  {
    id: 2,
    title: "app.Landing.Service_2.Header",
    description: "app.Landing.Service_2.Description",
    img: "services/gallery_2.png",
  },
];
