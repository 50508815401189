import React, { useContext, useEffect } from "react";
import { withLanding } from "../../hocs/withLanding";
import TResult from "../../components/Result";
import { useHistory, useParams } from "react-router-dom";
import TNavigator from "../../components/Navigator";
import { SearchOutlined } from "@ant-design/icons";

import { VerifaiContext } from "../../context/ws/VerifaiProvider";
import { Button, Result } from "antd";
import { ROUTES_GENERAL } from "../../utilities/Constant";

import { t } from "../../utilities/Message";

import { ReactSVG } from "react-svg";
import { pathServer } from "../../utilities/Function";

import "./styles.scss";
import { page_view } from "../../hooks/usePageView";
import { EVENTS_TGM } from "../../utilities/Events";
import { LanguageContext } from "../../context/i18n/LanguageProvider";

export const TIMEOUT_SESSION = 7000;

const ErrorResponse = ({ error, data }) => {
  if (!error) return;

  if (error)
    return (
      <Result
        className="result-antd pt-1"
        status="500"
        title="Error"
        subTitle={error?.message}
      />
    );

  if (!data?.results?.diffusion?.sucess)
    return (
      <Result
        className="result-antd"
        icon={
          <ReactSVG src={pathServer.PATH_IMG + "result/error_prediction.svg"} />
        }
        title="Ups! Algo no ha funcionado"
        subTitle={
          "Asegúrate de que solamente aparezca una cara en tu contenido y si en un video que se entuentre en los primeros 10s del clip."
        }
      />
    );
};

const ResultPage = () => {
  const { id, accessToken } = useParams<{ id: string; accessToken: string }>();
  const { loading, data, error, onCheckResult, retry } =
    useContext(VerifaiContext);

  const history = useHistory();
  const { locale } = useContext(LanguageContext);

  const _onClick = () => {
    history.push(ROUTES_GENERAL.HOME_PAGE);
  };

  const onLoadData = async () => {
    if (id && accessToken) {
      await onCheckResult(id, accessToken);
    }
  };

  useEffect(() => {
    onLoadData();
    page_view({ page_type: EVENTS_TGM.report, locale });
  }, []);

  useEffect(() => {
    if (retry > 0) {
      console.log("CALLING RETRY ......." + retry);
      setTimeout(() => {
        onLoadData();
      }, TIMEOUT_SESSION);
    }
  }, [retry]);

  return (
    <div className="result-page max-width">
      <ErrorResponse error={error} data={data} />
      {!error && (
        <>
          <TNavigator
            customBack={() => history.push(ROUTES_GENERAL.HOME_PAGE)}
            page={t("app.Header.Analysis")}
            withSearch={false}
            className="mt-2 mb-1"
          />

          <TResult id={id} className="mt-2" loading={loading} data={data} />
        </>
      )}
      <div className="text-center mt-2">
        <p style={{ fontSize: "1.3rem" }}>{t("app.result.wantToKeep")}</p>
        <Button
          size="large"
          className="mt-2 btn-more"
          icon={<SearchOutlined />}
          type="primary"
          onClick={_onClick}
        >
          {error ? "Try again" : t("app.result.more")}
        </Button>
      </div>
    </div>
  );
};

export default withLanding(ResultPage);
