const isObjEmpty = (obj: object) => {
  return Object.keys(obj).length === 0;
};

export const updatePath = (params) => {
  if (params) {
    const newPath = `${window.location.pathname}/${params}`;
    // Modificar la URL sin recargar la página completa
    window.history.replaceState({}, "", newPath);
  }

  return;
};

export const scrollToElementById = (id: string, height = 150) => {
  const element = document.getElementById(id);
  if (element) {
    window.scrollTo({ top: element.offsetTop + height, behavior: "smooth" });
  }
};

/**
 * Revoke buffer url from the storage
 * @param urlImage : image stored in the buffer
 * @returns
 */
export const removeImage = () => {
  const url = localStorage.getItem("processedFile");
  URL.revokeObjectURL(url);
  localStorage.removeItem("processedFile");
  return false;
};

export const msToTime = (duration: any) => {
  if (!duration) {
    return "-";
  }
  let milliseconds = Math.floor((duration % 1000) / 100);
  let seconds: string | number = Math.floor((duration / 1000) % 60);
  let minutes: string | number = Math.floor((duration / (1000 * 60)) % 60);
  let hours: string | number = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
};
