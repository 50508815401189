import React from "react";
import { Row, Col } from "antd";

import { pathServer } from "../../utilities/Function";
import TDropZone from "../DropZone";
import { t } from "../../utilities/Message";
import "./style.scss";

interface BannerProps {
  className?: string;
  uploadFile?: (params: File) => void;
  loading: boolean;
}

const TBanner = (props: BannerProps) => {
  return (
    <div className={`banner ${props.className} max-padding`}>
      <Row gutter={[16, 16]} className="max-width">
        <Col className="w-100">
          <h1 className="banner__title">
            <img
              src={pathServer.PATH_LOGO + "logo_color.svg"}
              alt="logo"
              width={250}
              className="img-full"
            />
          </h1>
          <p className="banner__subtitle font-medium-2">
            {t("app.Landing.Banner.Title", "")}
          </p>

          <TDropZone loading={props.loading} uploadFile={props.uploadFile} />
        </Col>
      </Row>
      <img
        src={pathServer.PATH_IMG + "main/background.gif"}
        alt="banner-mobile"
        className="to-md w-100"
      />
    </div>
  );
};

TBanner.displayName = "TBanner";

TBanner.defaultProps = {
  className: "",
};

export default TBanner;
