import React, { useRef } from "react";
import { Carousel } from "antd";

import { pathServer } from "../../utilities/Function";

import { CAROUSEL_ITEMS } from "./Constants";
import { ReactSVG } from "react-svg";
import { t } from "../../utilities/Message";
import { useHistory } from "react-router-dom";
import "./style.scss";

// Define your props here
interface CarouselProps {
  className?: string;
}

function CustomNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="carousel__dots-next pointer" onClick={onClick}>
      <ReactSVG src={pathServer.PATH_ICONS + "ic_right.svg"} />
    </div>
  );
}

function CustomPrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="carousel__dots-prev pointer" onClick={onClick}>
      <ReactSVG src={pathServer.PATH_ICONS + "ic_left.svg"} />
    </div>
  );
}

const TComingSoon = (props: CarouselProps) => {
  const carouselRef = useRef(null);

  const onChange = (currentSlide: number) => {
    console.log(currentSlide);
  };

  return (
    <div className={`coming_soon ${props.className}`}>
      <Carousel
        className="max-width"
        ref={carouselRef}
        swipe
        afterChange={onChange}
        dots={{ className: "custom-dots" }}
      >
        {CAROUSEL_ITEMS.map((carousel) => (
          <div className="carousel__container" key={carousel.id}>
            <div className="carousel__dots">
              <CustomPrevArrow onClick={() => carouselRef.current.prev()} />
              <CustomNextArrow onClick={() => carouselRef.current.next()} />
            </div>
            <div className="carousel__content m-auto">
              <h1 className="services__title font-bold">
                {t("app.coming.title")}
              </h1>
              <h2 className="services__subTitle font-bold">
                {t(carousel.title, "")}
              </h2>
              <p className="mt-1 services__description">
                {t(carousel.description, "")}
              </p>
            </div>
            <div className="m-auto">
              <img src={carousel.img} alt={carousel.title} />
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

TComingSoon.displayName = "TComingSoon";

TComingSoon.defaultProps = {
  className: "",
};

export default TComingSoon;
