import React, { createContext, useState, ReactNode } from "react";

import { container } from "../../../inject_container/injection";
import { IMAGE_PORT, ImagePort } from "../../../domain/port/inbound/ImagePort";
import { ImageResponse } from "../../../domain/entities/Photo";
import { ApiRespone } from "../../../domain/entities/Api";
import { STATUS_TASK } from "../../components/DropZone/Constants";

type WebSocketContextType = {
  isConnected: any;
  uploadData: (
    params: File,
    algorithm_list: string,
    recaptchaToken?: string
  ) => Promise<ImageResponse>;
  uploadText: (
    params: string,
    algorithm_list: string
  ) => Promise<ImageResponse>;
  onCheckResult: (params: string, accessToken: string) => void;
  data: ApiRespone;
  setData: React.Dispatch<React.SetStateAction<ApiRespone>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  error: any;
  setError: any;
  retry: number;
};

type ProviderProps = {
  children: ReactNode;
};

export const VerifaiContext = createContext<WebSocketContextType | undefined>(
  undefined
);

export const VerifaiProvider: React.FC<ProviderProps> = ({ children }: any) => {
  const [loading, setLoading] = useState(false);
  const [isConnected] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const [retry, setRetry] = useState(0);
  const [data, setData] = useState({});

  const imageRequest = container.get<ImagePort>(IMAGE_PORT);

  const uploadData = async (
    params: File,
    algorithm_list: string,
    recaptchaToken: string
  ) => {
    setLoading(true);
    const response = {};
    try {
      const response = await imageRequest.analyzeImage(
        params,
        algorithm_list,
        recaptchaToken
      );
      if (!response.id && response?.detail) {
        setError({ message: response?.detail });
        return response;
      }
      if (response) {
        setData(response);
        return response;
      }
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }

    return response;
  };

  const uploadText = async (params: string, algorithm_list: string) => {
    const response = {};
    setLoading(true);
    try {
      const response = await imageRequest.analyzeText(params, algorithm_list);

      if (response) {
        setData(response);
        return response;
      }
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }

    return response;
  };

  const onCheckResult = async (params: string, accessToken: string) => {
    setLoading(true);
    try {
      const response = await imageRequest.checkResult(params, accessToken);
      if (response.status === STATUS_TASK.done) {
        if (response?.subtasks[0].error_code === 5) {
          setError({ message: response?.subtasks[0].message });
        }
        setData(response);
        setLoading(false);
      } else {
        setRetry(retry + 1);
      }
    } catch (e) {
      setError(e);
    }
    return;
  };

  return React.createElement(VerifaiContext.Provider, {
    value: {
      isConnected,
      uploadData,
      uploadText,
      data,
      setData,
      loading,
      setLoading,
      error,
      setError,
      onCheckResult,
      retry,
    },
    children,
  });
};
