import React, { useContext } from "react";
import { Modal, Button, Form, Input, Checkbox } from "antd";
import { pathServer } from "../../utilities/Function";
import "./style.scss";
import { t } from "../../utilities/Message";
import moengage from "@moengage/web-sdk";
import { LanguageContext } from "../../context/i18n/LanguageProvider";
import { ROUTES_GENERAL, ROUTES_GENERAL_ESP } from "../../utilities/Constant";

interface LatchModalProps {
  className?: string;
  visible: boolean;
  loading?: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  userEmail?: string;
}

const EVENT_SUBSCRIBE = "formulario_completado";
const EVENT_SUBSCRIBE_ATTRIBUTES = {
  producto_formulario: "verifai",
  pagina_origen_formulario: "verifai",
  objetivo_formulario: "contactob2b",
};

const TContactModal = (props: LatchModalProps) => {
  const { locale } = useContext(LanguageContext);

  const { visible, onCancel, onSubmit, loading } = props;
  const [form] = Form.useForm();
  const lang_moengage = locale === "es" ? "es" : "en"; //TODO: need to change en/es
  console.log("🚀 ~ TContactModal ~ lang_moengage:", lang_moengage);

  const onFinish = (values) => {
    onSubscribe(values);
  };

  const onSubscribe = async (value) => {
    const { email, name, nameCompany, description } = value;
    try {
      const response = await moengage
        .add_unique_user_id(email)
        .then(() => moengage.add_email(email))
        .then(() => moengage.add_first_name(name))
        .then(() =>
          moengage.track_event(EVENT_SUBSCRIBE, {
            ...EVENT_SUBSCRIBE_ATTRIBUTES,
            idioma_formulario: lang_moengage,
          })
        )
        .then(() => moengage.add_user_attribute(EVENT_SUBSCRIBE, "true"))
        .then(() =>
          moengage.add_user_attribute("verifai_formulariob2b_" + locale, true)
        )
        .then(() => moengage.add_user_attribute("formulario_completado", true))
        .then(() =>
          moengage.add_user_attribute(
            "verifai_formulariob2b_companyname",
            nameCompany
          )
        )
        .then(() =>
          moengage.add_user_attribute(
            "verifai_formulariob2b_comments",
            description
          )
        )

        .then(() => moengage.destroy_session());
      if (response) {
        onSubmit();
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className={`TContactModal ${props.className}`}>
      <Modal
        open={visible}
        onCancel={onCancel}
        confirmLoading={loading}
        onOk={() => form.submit()}
        footer={[
          <Button
            key="submit"
            type="primary"
            size="large"
            style={{ borderRadius: "31px" }}
            className=" m-auto d-flex"
            loading={loading}
            onClick={() => form.submit()}
          >
            {t("app.Landing.CTA.FormButton")}
          </Button>,
        ]}
      >
        <div className="Contact__modal">
          <div className="header__logo mb-2">
            <img
              width={180}
              src={pathServer.PATH_LOGO + "logo_color.svg"}
              className="d-flex pointer"
              alt="logo"
            />
          </div>
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item
              name="name"
              label={t("app.Landing.CTA.FormName")}
              rules={[{ required: true }]}
            >
              <Input placeholder={t("app.Landing.CTA.FormPlaceholderName")} />
            </Form.Item>
            <Form.Item
              name="nameCompany"
              label={t("app.Landing.CTA.FormCompanyName")}
              rules={[{ required: true }]}
            >
              <Input
                placeholder={t("app.Landing.CTA.FormPlaceholderCompanyName")}
              />
            </Form.Item>
            <Form.Item
              name="email"
              label={t("app.Landing.CTA.FormCompanyEmail")}
              rules={[{ required: true }]}
            >
              <Input
                placeholder={t("app.Landing.CTA.FormPlaceholderCompanyEmail")}
              />
            </Form.Item>
            <Form.Item
              name="description"
              label={t("app.Landing.CTA.FormContent")}
            >
              <Input.TextArea
                rows={3}
                placeholder={t("app.Landing.CTA.FormPlaceholderContent")}
              />
            </Form.Item>
            <Form.Item
              name="terms"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(t("app.Landing.Footer.emailValidate")),
                },
              ]}
            >
              <Checkbox className="mr-0">
                {locale === "en" ? (
                  <div>
                    I have read and accept the{" "}
                    <a
                      href={ROUTES_GENERAL.PRIVACY}
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "#4343ff", fontWeight: "bold" }}
                    >
                      {" "}
                      Privacy Policy{" "}
                    </a>
                  </div>
                ) : (
                  <div>
                    He leído y acepto la
                    <a
                      href={ROUTES_GENERAL_ESP.PRIVACY}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Política de Privacidad{" "}
                    </a>
                  </div>
                )}
              </Checkbox>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

TContactModal.displayName = "TContactModal";

TContactModal.defaultProps = {
  className: "",
};

export default TContactModal;
