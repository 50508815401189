export const getResults = (result, typeFile = "image") => {
  const data = {
    color: "",
    text: "",
    className: "",
    title: "",
    content: "",
    result: "",
  };
  if (result >= 0 && result < 33.33) {
    data.color = "#0096EB";
    switch (typeFile) {
      case "image":
      default:
        data.title = "app.result.image_title";
        data.content = "app.result.image_low";
        data.result = "app.result.image_result";
        break;
      case "video":
        data.title = "app.result.video_title";
        data.content = "app.result.video_low";
        data.result = "app.result.video_result";
        break;
      case "text":
        data.title = "app.result.text_title";
        data.content = "app.result.text_low";
        data.result = "app.result.text_result";
        break;
    }

    data.className = "not-ai";
  } else if (result >= 33.33 && result < 66.66) {
    data.color = "orange";
    switch (typeFile) {
      case "image":
      default:
        data.title = "app.result.image_title";
        data.content = "app.result.image_med";
        data.result = "app.result.image_result";
        break;
      case "video":
        data.title = "app.result.video_title";
        data.content = "app.result.video_med";
        data.result = "app.result.video_result";
        break;
      case "text":
        data.title = "app.result.text_title";
        data.content = "app.result.text_med";
        data.result = "app.result.text_result";
        break;
    }

    data.className = "potentially-ai";
  } else if (result >= 66.66) {
    data.color = "#FF4D4F";
    switch (typeFile) {
      case "image":
      default:
        data.title = "app.result.image_title";
        data.content = "app.result.image_high";
        data.result = "app.result.image_result";
        break;
      case "video":
        data.title = "app.result.video_title";
        data.content = "app.result.video_high";
        data.result = "app.result.video_result";
        break;
      case "text":
        data.title = "app.result.text_title";
        data.content = "app.result.text_high";
        data.result = "app.result.text_result";
        break;
    }

    data.className = "likely-ai";
  }
  return data;
};
